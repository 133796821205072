/* Google Fonts */ 
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

/* Variables CSS*/

:root {
    --header-height: 3rem; 
    /* Colors*/
    /* Change favorite colors*/
    --hue-color: 230 ; 

    /* HSL color mode*/
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 53%, 57%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #FFF;

    /* Font and typography*/
    --body-font: 'Oxygen', sans-serif;

     /* .5rem = 8px, 1rem = 16px, 1.5 rem = 24px...*/
     --big-font-size: 2rem; 
     --h1-font-size: 1.5rem; 
     --h2-font-size: 1.25rem; 
     --h3-font-size: 1.125rem; 
     --normal-font-size: .938rem; 
     --small-font-size: .813rem; 
     --smaller-font-size: .75rem; 

     /* Font Weight*/
     --font-medium: 500; 
     --font-semi-bold: 600; 

      /* Margin Bottom*/
      /* .25rem = 4px, .5rem = 8px, .75rem= 12px ...*/
    --mb-0-25: .25rem ; 
    --mb-0-5: .5rem; 
    --mb-0-75: .75rem; 
    --mb-1: 1rem; 
    --mb-1-5: 1.5rem; 
    --mb-2: 2rem; 
    --mb-2-5: 2.5rem ; 
    --mb-3: 3rem; 

    /* Z index*/
    --z-tooltip: 10; 
    --z-fixed:  100; 
    --z-modal: 1000; 

}

/* Font size for large devices*/
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem ;
        --h1-font-size: 2.25rem ;
        --h2-font-size: 1.5rem ;
        --h3-font-size: 1.125rem;
        --normal-font-size: .938rem;
        --small-font-size: .813rem;
        --smaller-font-size: .75rem;
    }
}

/* Base */
*{
    box-sizing: border-box;
    padding: 0; 
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0 0 var(--header-height) 0; 
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color); 
    color: var(--text-color); 
}

h1,h2,h3,h4 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul {
    list-style:none; 
}

a {
    text-decoration:none; 
}

img {
    max-width: 100%; 
    height: auto; 
}

/* Reusable CSS Classes */

.section {
    padding: 2rem 0 4rem; 
}

.section__title {
    font-size: var(--h1-font-size);
}

.section__subtitle {
    display: block; 
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-3);
}

.section__title, 
.section__subtitle { 
    text-align: center; 
}

/* Layout */

.container {
    max-width: 768px;
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
}

.grid {
    display: grid; 
    gap: 1.5rem; 
}

.header{
    width: 100%; 
    position:fixed; 
    left: 0; 
    z-index: var(--z-fixed); 
    background-color: var(--body-color);

}

/* Nav */ 
.nav { 
    max-width: 968px;
    display: flex; 
    justify-content: space-between;
    align-items: center;

}

.nav__logo, 
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo:hover {
    color: var(--first-color);
}

.nav__toggle {
    font-size: 1.1rem; 
    cursor: pointer; 
}

.nav__toggle:hover {
    color: var(--first-color);
}

@media screen and (max-width: 767px) {
    .nav__menu {
        position: fixed; 
        bottom: -100%; 
        left: 0; 
        width: 100%; 
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem; 
        box-shadow: 0 -1px 4px rgba(0,0,0, .15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
}



.nav__list {
    grid-template-columns: repeat(3,1fr);
    gap: 2rem; 
}

.nav__link {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__link:hover {
    color: var(--first-color);
}

.nav__icon {
    font-size: 1.2rem;
}

.nav__close {
    position: absolute;
    right: 1.3rem; 
    bottom: .5rem; 
    font-size: 1.5rem;
    cursor:pointer; 
    color: var(--first-color);
}

.nav__close:hover {
    color: var(--first-color-alt);
}

/* show menu */

.show-menu {
    bottom: 0; 
}

/* Home */

.home__container {
    gap: 1rem; 
}

.home__content {
    grid-template-columns:  .5fr 3fr;
    padding-top: 3.5rem; 
    align-items: center; 
}

.home__social {
    display: grid; 
    grid-template-columns: max-content;
    row-gap: 1rem; 
}

.home__social-icon {
    font-size:1.25rem; 
    color: var(--first-color);
}

.home__social-icon:hover {
     color:var(--first-color-alt);
}

.home__blob {
    margin-bottom: var(--mb-2) ;
    
    border-radius: 50%;
    overflow: hidden;
    
}

.home__data {
    grid-column: 1/3;
}

.home__title {
    font-size: var(--big-font-size);
}

.home__subtitle {
    font-size: var(--h3-font-size);
    color: var(--text-color);
    font-weight: var(--font-medium) ;
    margin-bottom: var(--mb-0-75);
}

.home__description {
    margin-bottom: var(--mb-2);
}

.home__social {
     display:none;
}

.home__scroll-button {
    color: var(--first-color);
    transition: .3s; 
}

.home__scroll-button:hover {
    transform: translateY(.25rem);
}

.home__scroll-mouse {
    font-size:2rem; 
}

.home__scroll-name {
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
}

.home__scroll-arrow {
    font-size: 1.25rem; 
}

/* Buttons */

.button {
    display: inline-block;
    background-color: var(--first-color);
    color: #FFF; 
    padding: 1rem; 
    border-radius: .5rem;
    font-weight: var(--font-medium);
}

.button:hover {
    background-color: var(--first-color-alt);
}

.button__icon {
    font-size: 1.25rem; 
    margin-left: var(--mb-0-5);
    transition: .3s; 
}

.button--flex {
    display: inline-flex;
    align-items: center;

}

/*-------------------------------- About---------------------------------- */

.about__description {
    text-align: center;
    margin-bottom: var(--mb-2-5);
}

.about__info {
    display: flex; 
    justify-content: space-evenly;
    margin-bottom: var(--mb-2-5);
    
}

.about__info-title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--title-color);

}


.about__info-name {
    font-size: var(--smaller-font-size);
}

.about__info-title, 
.about__info-name {
    display: block;
    text-align: center;
}

.about__buttons {
    display:flex; 
    justify-content: center;
}

.about__photo {
    margin-bottom: var(--mb-2) ;
    width: 100%;
    height: 100%;
    border: 1rem solid var(--first-color);
}

/* Skills */ 

.skills__container {
    row-gap: 1; 
    
}

.skills__header {
    display:flex; 
    align-items: center;
    margin-bottom: var(--mb-2-5);
    cursor:pointer; 
    
}

.skills__icon, 
.skills__arrow {
    font-size: 2rem; 
    color: var(--first-color);
}

.skills__icon {
    margin-right: var(--mb-0-75);
}

.skills__title {
    font-size: var(--h3-font-size);
}

.skills__subtitle {
    font-size: var(--small-font-size);
    color:var(--text-color-light)
}

.skills__arrow {
    margin-left:auto; 
    transition: .4s; 
}

.skills__titles {
    display: flex; 
    justify-content: space-between;
    margin-bottom: var(--mb-0-5);
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills_bar, 
.skills__percentage {
    height: 5px; 
    border-radius: .25rem;
}

.skills__bar {
    background-color: var(--first-color-lighter);
}

.skills__percentage {
    display: block;
    background-color: var(--first-color);
}

.skills_list {
    row-gap: 1.5rem; 
    padding-left: 2.7rem;
}



/* Qualifications */


.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calendar {
    font-size: var(--smaller-font-size);
    color: var(--text-color-light);
}

.qualification__heading {
    padding: 2rem 0 4rem; 
    font-size: var(--h1-font-size);
    text-align: center; 
}

.c {
    max-width: 250px;
    max-height: 250px;
    width: 25ch;
    height: 25ch;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .front,
  .back {
    background-size: cover;
  }
  

/* Contact Me*/

.contact__container {
    row-gap: 3rem; 
}


.contact__information {
    display: flex; 
    margin-bottom: var(--mb-2);
}

.contact__icon {
    font-size: 2rem; 
    color: var(--first-color);
    margin-right: var(--mb-0-75);
}

.contact__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.contact__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.contact__content {
    background-color: var(--input-color);
    border-radius: .5rem;
    padding: .75rem 1rem .25rem; 
}

.contact__label {
    font-size: var(--smaller-font-size);
    color: var(--title-color);
}

.contact__input {
    width: 100%; 
    background-color: var(--input-color);
    color: var(--text-color);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    border: none;
    outline:none;
    padding: .25rem .5rem .5rem 0; 

}


/* Footer */

.footer {
    padding-top: 2rem; 
}

.footer-container {
    row-gap: 3.5rem; 
}

.footer__bg {
    background-color: var(--first-color-second);
    padding: 2rem 0 3rem; 
}

.footer__title {
    font-size: var(--h1-font-size);
    margin-bottom: var(--mb-0-25)
}

.footer__subtitle  {
    font-size: var(--small-font-size);
}

.footer__links {
    display: flex; 
    flex-direction: column;
    row-gap: 1.5rem; 
}

.footer__link:hover {
    color: var(--first-color-lighter);
}

.footer__social {
    font-size: 1.25rem;
    margin-right: var(--mb-3);

}

.footer__social:hover {
    color: var(--first-color-lighter);
}

.footer__copy {
    font-size: var(--smaller-font-size);
    text-align: center;
    color:var(--text-color-light);
    margin-top: var(--mb-3);
}

.footer__title, 
.footer__subtitle,
.footer__link, 
.footer__social {
    color: #FFF; 
}

/* Media Queries*/

/* For small devices*/
@media screen and (max-width:350px) {
    .container {
        margin-left: var(--mb-1);
        margin-right: var(--mb-1);
    }

    .nav__menu {
        padding: 2rem .25rem 4rem; 
    }

    .nav__list {
        column-gap: 0; 
    }
    .home__content {
        grid-template-columns: .25fr 3fr;
    }
    
    .skills__title {
        font-size: var(--normal-font-size);
    }
    .qualification__data {
        gap: .5rem; 
    }
    
}
/* For medium devices*/

@media screen and (min-width: 568px) {
    .home__content {
        grid-template-columns: max-content 1fr 1fr;
    }
    .home__data {
        grid-column: initial;
    }
    .home__img {
        order:1; 
        justify-self: center;
    }
    .about__container,
    .portfolio__content,
    .project__container,
    .contact__container,
    .footer__container {
        grid-template-columns: repeat(2, 1fr);
    }

    .qualification__section {
        display: grid;
        grid-template-columns: .6fr;
        justify-content: center;
    }
}

@media screen and (min-width: 768px){
    .container{
        margin-left: auto ;
        margin-right: auto;
    }

    body {
        margin:0; 
    }

    .section {
        padding: 6rem 0 2rem;
    }

    .section__subtitle {
        margin-bottom: 4rem; 
    }
    .header {
        top: 0; 
        bottom: initial; 
    }

    .header, 
    .main, 
    .footer__container {
        padding: 0 1rem; 
    }

    .nav {
        height: calc(var(--header-height) + 1.5rem); 
        column-gap: 1rem;
    }
    .nav__icon, 
    .nav__close, 
    .nav__toggle {
        display: none; 
    }
    .nav__list {
        display: flex;
        column-gap: 2rem;
    }
    .nav__menu {
        margin-left: auto;
    }
    .change-theme {
        margin:0; 
    }
    .home__container {
        row-gap: 5rem; 
    }
    .home__content {
        padding-top: 5.5rem;
        column-gap: 2rem;
    }
    .home__blob {
        width: 270px; 
    }
    .home__scroll {
        display:block; 
    }
    .home__scroll-button {
        margin-left: 3rem; 
    }
    .about__container{
        column-gap: 5rem; 
    }
    .about__img {
        width: 350px; 
    }
    .about__description{
        text-align: initial;
    }
    .about__info {
        justify-content: space-between;
    }
    .about__buttons{
        justify-content: initial;
    }

    .qualification__tabs {
        justify-content: center;
    }
    .qualification__button {
        margin:0 var(--mb-1);
    }
    .qualification__sections{
        grid-template-columns: .5fr;
    }

    .portfolio__img {
        width: 320px;
    }
    .portfolio__content{
        align-items: center;
    }

    .footer_-container{
        grid-template-columns: repeat(3, 1fr);
    }
    .footer__bg {
        padding: 3rem 0 3.5rem; 
    }
    .footer__links {
        flex-direction: row;
        column-gap: 2rem;
    }
   
    .footer__copy {
        margin-top: 4.5rem;
    }
}

/* For large devices*/

@media screen and (min-width: 1024px) {
    .header,
    .main, 
    .footer__container {
        padding: 0; 
    }
    .home__blob {
        width: 320px;
    }
    .home__social {
        transform: translate(-6rem);
    }

    .portfolio__content {
        column-gap: 5rem;
    }
   
    .contact__form {
        width: 460px; 
    }
    .contact__inputs {
        grid-template-columns: repeat(2, 1fr);
    }
}